<template>
    <div v-if="orderData" class="bg-white mb-3">
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <h1 class="h4 mb-0">Resolve Order Issues - {{ orderData.patient_name }}</h1>
            </div>
        </div>
    </div>

    <ul v-if="orderTicketsData && orderTicketsData.data.length" class="ticket-list mb-4">
        <li class="header-row">
            <div class="row align-items-center">
                <div class="col">
                    <div class=""><strong>Issue</strong></div>
                </div>
                <div class="col flex-grow-0 flex-sm-grow-1 d-flex justify-content-between align-items-center">
                    <div class="d-none d-md-block text-center">
                        <div class=""><strong>Created Date</strong></div>
                    </div>
                    <div></div>
                </div>
            </div>
        </li>
        <li v-for="ticket of orderTicketsData.data" v-bind:key="ticket.id">
            <div class="row align-items-center">
                <div class="col">
                    <!-- <div class=""><strong>{{ ticket.type.name }}</strong></div> -->
                     <div class="">{{ ticket.type.name }}</div>
                </div>
                <div class="col flex-grow-0 flex-sm-grow-1 d-flex justify-content-between align-items-center">
                    <div class="d-none d-md-block text-center">
                        <div class="order-date">{{ new Date(ticket.created_at).toLocaleDateString() }}</div>
                    </div>
                    <div class="">
                        <router-link class="btn btn-secondary" :to="{ name: 'TicketResolve', params: { ticket_id: ticket.id }}">View</router-link>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</template>

<style scoped lang="scss">

    .ticket-list
    {
        list-style: none;
        padding: 0px;
        margin: 0px;

        > li
        {
            border-top: 1px solid #EEEEEE;
            padding: 10px 0px;

            strong
            {
                color: $swift-dark-grey;
            }
        }

        .header-row
        {
            border-top: 0px;
        }
    }
</style>

<script>
    import api from "@/services/api";

    export default {
        props: {
            id: {
                required: true
            }
        },
        data() {
            return {
                orderData: null,
                orderTicketsData: null,
                activeTab: 'details',
                modal: null,
                ticketComment: null,
                disableCommentSubmitBtn: false,
            };
        },
        mounted () {
            this.fetchOrderData();
            this.fetchOrderTicketsData();
        },
        methods: {
            fetchOrderData: function() {   
                api
                .get('/api/v1/orders/' + this.$route.params.id)
                .then((response) => {
                    this.orderData = response.data.data;
                })
                .catch(() => {
                    console.log('Catch order data error');
                });
            },
            fetchOrderTicketsData: function() {                
                api
                .get('/api/v1/orders/' + this.$route.params.id + '/tickets')
                .then((response) => {
                    this.orderTicketsData = response;
                })
                .catch(() => {
                    console.log('Catch ticket order error');
                });
            },
        },
    }
</script>
